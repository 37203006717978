<template>
  <table-wrapper title="Reportes de Dragado">
    <template #actions>
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="6" sm="5" md="4">
            <v-menu
              :close-on-content-click="false"
              v-model="openPickerMenu"
              location="bottom"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  :model-value="`${months[month]} de ${year}`"
                  variant="outlined"
                  hide-details
                  label="Selecciona el mes"
                >
                  <template #prepend-inner>
                    <fa-icon
                      icon="calendar-week"
                      class="mr-2"
                      style="color: #9e9e9e"
                    ></fa-icon>
                  </template>
                </v-text-field>
              </template>
              <div class="d-flex elevation-2 z-index:99 mt-1">
                <v-date-picker-months
                  class="pb-2 bg-white"
                  @update:model-value="openPickerMenu = false"
                  v-model="month"
                  color="white"
                ></v-date-picker-months>
                <v-divider vertical></v-divider>
                <v-date-picker-years
                  class="pb-2 bg-white"
                  v-model="year"
                  min="2015-01"
                  :max="`${now.getFullYear()}-12`"
                ></v-date-picker-years>
              </div>
            </v-menu>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="toggleCreatingReport" color="primary" size="large">
              + Crear reporte
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #table>
      <v-table>
        <thead>
          <tr>
            <th scope="col">Reporte</th>
            <th scope="col">Fecha</th>
            <th scope="col">Estado</th>
            <th scope="col">Modificado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td class="text-center" colspan="5">Cargando...</td>
          </tr>
          <tr v-if="!isLoading && reports.length === 0">
            <td class="text-center" colspan="5">
              No se encontraron reportes en {{ year }}-{{ month + 1 }}.
            </td>
          </tr>
          <tr v-show="!isLoading" v-for="report in reports" :key="report.date">
            <td>
              {{ report.name }}
            </td>
            <td>
              {{ new Date(`${report.date}T00:00:00`).toLocaleDateString() }}
            </td>
            <td>
              <div class="d-flex ga-2">
                {{ statusNames[report.status] }}
                <popover
                  v-if="report.errors?.length > 0"
                  title="Información del error"
                >
                  <template v-slot:toggle>
                    <v-icon
                      size="x-small"
                      icon="fas fa-circle-question"
                      color="error"
                    ></v-icon>
                  </template>
                  <template v-slot:content>
                    <div>
                      <ul class="mb-2">
                        <li v-for="error in report.errors" :key="error">
                          <small>{{ error }}</small>
                        </li>
                      </ul>
                    </div>
                  </template>
                </popover>
              </div>
            </td>
            <td>
              {{ new Date(`${report.updated}Z`).toLocaleString() }}
            </td>
            <td>
              <div class="d-flex">
                <button-table-action
                  :aria-disabled="disableDownload"
                  @click="downloadCSV(report)"
                >
                  <v-icon icon="fas fa-download"></v-icon>
                </button-table-action>
                <button-table-action
                  :aria-disabled="disableDownload"
                  @click="deleteReport = report"
                >
                  <v-icon icon="fas fa-trash"></v-icon>
                </button-table-action>
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
    </template>
    <template #footer>
      <div>
        <modal-create-report
          :show="isCreatingReport"
          @toggle="toggleCreatingReport"
          @created="onCreateReport"
        >
        </modal-create-report>
        <modal-delete-report
          :show="deleteReport != null"
          :report="deleteReport"
          @accept="onDeleteReport"
          @toggle="deleteReport = null"
        >
        </modal-delete-report>
      </div>
    </template>
  </table-wrapper>
</template>

<script>
import TableWrapper from "@/components/TableWrapper.vue";
import ModalCreateReport from "../components/reports/ModalCreateReport.vue";
import ModalDeleteReport from "../components/reports/ModalDeleteReport.vue";
import ButtonTableAction from "@/components/shared/Button/ButtonTableAction.vue";
import { listReports, downloadReport, deleteReport } from "../api/reports";

export default {
  components: {
    ModalCreateReport,
    ModalDeleteReport,
    ButtonTableAction,
    TableWrapper,
  },
  data() {
    const now = new Date();
    return {
      now,
      reports: [],
      isCreatingReport: false,
      deleteReport: null,
      isLoading: true,
      openPickerMenu: false,
      statusNames: {
        PENDING: "Pendiente...",
        PROCESSING: "Generando...",
        COMPLETED: "Completado",
        FAILED: "Falló",
      },
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      disableDownload: false,
      year: now.getFullYear(),
      month: now.getMonth(),
    };
  },
  methods: {
    toggleCreatingReport() {
      this.isCreatingReport = !this.isCreatingReport;
    },
    reloadReports: async function reloadReports() {
      this.isLoading = true;
      try {
        const response = await listReports({
          year: this.year,
          month: this.month,
        });
        const reports = await response.json();
        this.reports = reports.map((report) => {
          return {
            name: report.filename,
            created: report.created,
            updated: report.updated,
            date: report.date,
            status: report.status,
            errors: report.errors ?? [],
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    downloadCSV(report) {
      const download = async () => {
        this.disableDownload = true;
        const response = await downloadReport({ reportDate: report.date });
        try {
          const url = await response.json();
          const a = document.createElement("a");
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } finally {
          this.disableDownload = false;
        }
      };
      download();
    },
    onCreateReport({ reportDate, filename }) {
      this.reports = this.reports.filter(
        (report) => report.date !== reportDate
      );
      this.reports.push({
        name: filename,
        date: reportDate,
      });
    },
    onDeleteReport() {
      const report = this.deleteReport;
      this.deleteReport = null;
      const del = async () => {
        this.isLoading = true;
        const response = await deleteReport({ date: report.date });
        if (response.ok) {
          this.reports = this.reports.filter((rep) => rep.date !== report.date);
        }
        this.isLoading = false;
      };
      del();
    },
  },
  watch: {
    month: {
      handler() {
        this.reloadReports();
      },
    },
  },
  created() {
    this.reloadReports();
  },
};
</script>

<style>
.v-date-picker-months__content {
  grid-template-columns: repeat(3, 1fr) !important;
}

.v-date-picker-years__content {
  grid-template-columns: repeat(1, 1fr) !important;
}
</style>
